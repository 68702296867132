import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Construction';
import AssessmentIcon from '@mui/icons-material/PlaylistAddCheck';
import SchoolIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/StarBorder';

import axios from 'axios';
import useWebApp from './twa/useWebApp';
import Courses from './course/Main';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedAssignment, setTab, setUserLanguage } from "./store";
import '@telegram-apps/telegram-ui/dist/styles.css';
import { Route, Routes } from 'react-router-dom';
import CourseDetails from './course/CourseDetails';
import { setAccessToken } from "./store";
import StepContent from "./course/content/StepContent";
import Assignments from "./Assignments";
import AssignmentDetails from "./AssignmentDetails";
import Bots from "./Bots";
import Payments from "./Payments";
import Students from "./Students";
import './App.css';
import { translate } from "./translations";
import Transactions from "./Transactions";
import StudentDetail from "./StudentDetail";
import WelcomeModal from "./WelcomeModal";
import Profile from "./Profile";
import LanguageSelection from "./LanguageSelection";

axios.defaults.baseURL = "https://api.coob.app";
axios.defaults.withCredentials = true;

export default function MyApp() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showNavigation = useSelector(state => state.showNavigation);
    const value = useSelector(state => state.tab);

    const [loading, setLoading] = useState(true);
    const [dark, setDark] = useState(false);
    const [user, setUser] = useState(null);
    const language = useSelector(state => state.userLanguage);

    const [topMargin, setTopMargin] = useState(0);

    const WebApp = useWebApp(); // WebApp вызывается в теле компонента

    useEffect(() => {
        if (WebApp) {
            // if mobile app
            if (WebApp.platform.includes("android") || WebApp.platform.includes("ios")) {
                WebApp.requestFullscreen();
                setTopMargin(85);
            }

            WebApp.SettingsButton.show();
            WebApp.SettingsButton.onClick(() => {
                navigate('/profile');
            });

            WebApp.setHeaderColor('secondary_bg_color');
            if (WebApp.platform !== "macos") {
                WebApp.enableClosingConfirmation();
            }

            WebApp.disableVerticalSwipes();
            WebApp.onEvent('viewportChanged', () => WebApp.expand());
        } else {
            // redirect ..
            window.location.href = "https://coob.app";
        }
    }, [WebApp]);

    useEffect(() => {
        if (WebApp) {
            setLoading(true);

            const tabMapping = {
                'assignments': 1,
                'students': 2,
                'statistics': 3,
                'main': 0
            };

            const currentPath = location.pathname.split('/')[1];
            const initialTab = tabMapping[currentPath] !== undefined ? tabMapping[currentPath] : 0;
            dispatch(setTab(initialTab));

            const colorScheme = WebApp.colorScheme || 'light';
            setDark(colorScheme === 'dark');

            axios.post("/v1/auth/login/telegram-app", {
                web_app_data: WebApp.initData,
            }, {
                withCredentials: true,
            }).then(response => {
                const token = response.data.data.access_token;
                dispatch(setAccessToken(token));
                setUser(response.data.data.user);
                dispatch(setUserLanguage(response.data.data.user.language || 'en'));
                setLoading(false);

                if (!WebApp.isExpanded) {
                    WebApp.expand();
                }
            }).catch(error => {
                console.error("Error:", error);
                setLoading(false);
            });
        }
    }, []);

    const StyledContainer = styled('div')({
        backgroundColor: WebApp?.themeParams?.secondary_bg_color,
        color: WebApp?.themeParams?.text_color,
        backgroundImage: 'none',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '70px',
    });

    const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
        backgroundColor: `${WebApp?.themeParams?.secondary_bg_color} !important`,
        color: WebApp?.themeParams?.text_color,
        position: 'fixed',
        bottom: 0,
        height: '70px',
        paddingBottom: '16px',
        width: '100%',
        boxShadow: theme.shadows[4],
        zIndex: 1000,
        '& .Mui-selected': {
            color: `${WebApp?.themeParams?.button_color} !important`,
        },
        '& .MuiBottomNavigationAction-label': {
            color: WebApp?.themeParams?.subtitle_text_color,
            fontSize: '10px',
        },
        display: showNavigation ? 'flex' : 'none',
    }));

    const theme = createTheme({
        palette: {
            mode: dark ? 'dark' : 'light',
        },
    });

    const renderPage = () => (
        <Routes>
            <Route path="/" element={<Courses user={user} themeParams={WebApp?.themeParams} language={language} />} />
            <Route path="/courses/:courseId" element={<CourseDetails themeParams={WebApp?.themeParams} language={language} />} />
            <Route path="/courses/:courseId/steps/:stepId" element={<StepContent themeParams={WebApp?.themeParams} language={language} dark={dark} />} />
            <Route path="/assignments" element={<Assignments themeParams={WebApp?.themeParams} language={language} />} />
            <Route path="/assignments/:id" element={<AssignmentDetails themeParams={WebApp?.themeParams} language={language} />} />
            <Route path="/bots" element={<Bots themeParams={WebApp?.themeParams} language={language} />} />
            <Route path="/payments" element={<Payments themeParams={WebApp?.themeParams} language={language} />} />
            <Route path="/students" element={<Students themeParams={WebApp?.themeParams} language={language} />} />
            <Route path="/students/:id" element={<StudentDetail themeParams={WebApp?.themeParams} language={language} />} />
            <Route path="/statistics" element={<Transactions themeParams={WebApp?.themeParams} language={language} />} />
            <Route path="/profile" element={<Profile user={user} themeParams={WebApp?.themeParams} language={language} />} />
            <Route path="/profile/language" element={<LanguageSelection themeParams={WebApp?.themeParams} language={language} user={user} />} />
        </Routes>
    );

    return (
        <ThemeProvider theme={theme}>
            <StyledContainer>
                <WelcomeModal language={language} themeParams={WebApp?.themeParams} user={user}/>
                <div style={{ flex: 1, paddingTop: `${topMargin}px`, }}>{renderPage()}</div>
                <StyledBottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        dispatch(setTab(newValue));

                        switch (newValue) {
                            case 0:
                                navigate('/');
                                break;
                            case 1:
                                navigate('/assignments');
                                break;
                            case 2:
                                navigate('/students');
                                break;
                            case 3:
                                navigate('/statistics');
                                break;
                            default:
                                navigate('/');
                        }
                    }}
                >
                    <BottomNavigationAction label={translate(language, 'constructor')} icon={<HomeIcon sx={{ fontSize: 28 }} />} />
                    <BottomNavigationAction label={translate(language, 'assignments_menu')} icon={<AssessmentIcon sx={{ fontSize: 28 }} />} />
                    <BottomNavigationAction label={translate(language, 'students')} icon={<SchoolIcon sx={{ fontSize: 28 }} />} />
                    <BottomNavigationAction label={translate(language, 'transactions')} icon={<ReceiptIcon sx={{ fontSize: 28 }} />} />
                </StyledBottomNavigation>
            </StyledContainer>
        </ThemeProvider>
    );
}