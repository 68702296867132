import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/system';
import useWebApp from './twa/useWebApp';
import { translate } from "./translations";
import { useDispatch, useSelector } from "react-redux";
import { setUserLanguage } from "./store";
import axios from 'axios';

const StyledDialog = styled(Dialog)(({ themeParams }) => ({
    '& .MuiPaper-root': {
        backgroundColor: themeParams.section_bg_color,
        color: themeParams.text_color,
        backgroundImage: 'none',
        borderRadius: '18px',
        padding: '16px',
    }
}));

const StyledDialogContent = styled(DialogContent)(({ themeParams }) => ({
    color: themeParams.subtitle_text_color,
    fontSize: '15px',
}));

const StyledButton = styled(Button)(({ themeParams }) => ({
    backgroundColor: themeParams.button_color,
    borderRadius: '12px',
    color: themeParams.button_text_color,
    padding: '10px',
    fontSize: '14px',
    '&:hover': {
        backgroundColor: themeParams.button_color,
    },
    '&:active': {
        backgroundColor: themeParams.button_color,
    }
}));

const StyledSelect = styled(Select)(({ themeParams }) => ({
    backgroundColor: themeParams.secondary_bg_color,
    borderRadius: '8px',
    color: themeParams.button_text_color,
    fontSize: '14px',
    width: '100%',
}));

const LanguageContainer = styled('div')(({ themeParams }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '12px',
    marginTop: '16px',
}));

const WelcomeModal = ({ themeParams, user }) => {
    const [open, setOpen] = useState(false);
    const language = useSelector(state => state.userLanguage);
    const accessToken = useSelector(state => state.accessToken);
    const WebApp = useWebApp();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!WebApp) {
            setOpen(true);
            return;
        }

        WebApp.CloudStorage.getItem('welcome_modal_shown', (error, value) => {
            if (error) {
                console.error('Ошибка при получении данных из CloudStorage', error);
                setOpen(true);
                return;
            }
            if (!value) {
                setOpen(true);
            }
        });
    }, [WebApp]);

    const handleClose = () => {
        setOpen(false);
        WebApp.CloudStorage.setItem('welcome_modal_shown', 'true', (error) => {
            if (error) {
                console.error('Ошибка при сохранении данных в CloudStorage', error);
            }
        });
    };

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;

        axios.put(`/v1/users/${user?.id}`, { language: newLanguage }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(response => {
            dispatch(setUserLanguage(newLanguage));
        }).catch(error => {
            console.error('Ошибка при обновлении языка', error);
        });
    };

    const languages = [
        { code: 'en', label: 'English', localized: 'English' },
        { code: 'ru', label: 'Russian', localized: 'Русский' },
        { code: 'es', label: 'Spanish', localized: 'Español' },
        { code: 'fr', label: 'French', localized: 'Français' },
        { code: 'de', label: 'German', localized: 'Deutsch' },
        { code: 'zh', label: 'Chinese', localized: '中文' }
    ];

    return (
        <StyledDialog open={open} onClose={handleClose} themeParams={themeParams}>
            <DialogTitle>{translate(language, 'welcome_title').replace('%s', user?.full_name)}</DialogTitle>
            <StyledDialogContent themeParams={themeParams}>
                <p dangerouslySetInnerHTML={{ __html: translate(language, 'welcome_message').replace(/\n/g, '<br>') }} />
                <LanguageContainer themeParams={themeParams}>
                    <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>{translate(language, "select_language")}</Typography>
                    <StyledSelect
                        value={language}
                        onChange={handleLanguageChange}
                        themeParams={themeParams}
                    >
                        {languages.map(({ code, label, localized }) => (
                            <MenuItem key={code} value={code}>{label} ({localized})</MenuItem>
                        ))}
                    </StyledSelect>
                </LanguageContainer>
            </StyledDialogContent>
        </StyledDialog>
    );
};

export default WelcomeModal;
