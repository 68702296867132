import React, { useEffect, useState } from 'react';
import { Box, Typography, ListItem, Divider, Radio, RadioGroup, FormControlLabel, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUserLanguage } from './store';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { styled } from '@mui/system';
import { translate } from "./translations";
import useWebApp from "./twa/useWebApp";

const Section = styled(Box)(({ themeParams }) => ({
    padding: '12px',
    backgroundColor: themeParams.section_bg_color,
    borderRadius: '12px',
    marginTop: '12px',
    position: 'relative',
    width: '100%',
    boxShadow: '0 2px 10px rgba(0,0,0,0.05)'
}));

const Label = styled(Typography)(({ themeParams }) => ({
    color: themeParams.section_header_text_color,
    fontSize: '0.85rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.08em',
    marginBottom: '10px',
    marginTop: '12px',
    textAlign: 'center'
}));

const LoaderWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '12px'
});

const LanguageSelection = ({ themeParams, language, user }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(state => state.accessToken);
    let WebApp = useWebApp();

    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const [loading, setLoading] = useState(false);

    const languages = [
        { code: 'en', label: 'English', localized: 'English' },
        { code: 'ru', label: 'Russian', localized: 'Русский' },
        { code: 'es', label: 'Spanish', localized: 'Español' },
        { code: 'fr', label: 'French', localized: 'Français' },
        { code: 'de', label: 'German', localized: 'Deutsch' },
        { code: 'zh', label: 'Chinese', localized: '中文' }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);

        if (WebApp) {
            WebApp.BackButton.show();
            WebApp.BackButton.onClick(() => {
                navigate(`/profile`);
            });
            return () => {
                WebApp.BackButton.hide();
            };
        }
    }, [WebApp]);

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
        setLoading(true);
        dispatch(setUserLanguage(newLanguage));

        axios.put(`/v1/users/${user?.id}`, { language: newLanguage }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(response => {
            console.log('Язык успешно обновлен', response.data);
            navigate(-1);
        }).catch(error => {
            console.error('Ошибка при обновлении языка', error);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Box sx={{ padding: '16px', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Label themeParams={themeParams}>{translate(language, 'select_language')}</Label>
            <Section themeParams={themeParams}>
                {loading && (
                    <LoaderWrapper>
                        <CircularProgress size={32} />
                    </LoaderWrapper>
                )}
                <RadioGroup value={selectedLanguage} onChange={handleLanguageChange} disabled={loading} sx={{ width: '100%' }}>
                    {languages.map((lang, index) => (
                        <React.Fragment key={lang.code}>
                            <ListItem disablePadding sx={{ padding: '10px 16px' }}>
                                <FormControlLabel
                                    value={lang.code}
                                    control={<Radio disabled={loading} />}
                                    label={`${lang.label} (${lang.localized})`}
                                    sx={{ width: '100%' }}
                                />
                            </ListItem>
                            {index !== languages.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </RadioGroup>
            </Section>
        </Box>
    );
};

export default LanguageSelection;