import React, { useState, useEffect } from 'react';
import useWebApp from './twa/useWebApp';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {translate} from "./translations";

const DesktopEditTip = ({language, themeParams}) => {
    const [visible, setVisible] = useState(false);
    let WebApp = useWebApp();

    useEffect(() => {
        if (!WebApp) {
            setVisible(true);
            return;
        }

        WebApp.CloudStorage.getItem('desktop_edit_tip_closed', (error, value) => {
            if (error) {
                console.error('Ошибка при получении данных из CloudStorage', error);
                setVisible(true);
                return;
            }
            if (!value) {
                setVisible(true);
            }
        });

    }, [WebApp]);

    const handleClose = () => {
        setVisible(false);
        WebApp.CloudStorage.setItem('desktop_edit_tip_closed', 'true', (error, result) => {
            if (error) {
                console.error('Ошибка при сохранении данных в CloudStorage', error);
            }
        });
    };

    return (
        visible && (
        <Alert
            severity="info"
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            sx={{ mb: 2, marginLeft: 2, marginRight: 2, borderRadius: 5, backgroundColor: themeParams.secondary_bg_color }}
        >
            <AlertTitle>{translate(language, 'content_alert_title')}</AlertTitle>
            {translate(language, 'content_alert_body')}
        </Alert>
        )
    );
};

export default DesktopEditTip;
