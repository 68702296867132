import React, {useEffect} from 'react';
import { Avatar, Box, Typography, Card, List, ListItem, ListItemIcon, ListItemText, Divider, ListItemSecondaryAction } from '@mui/material';
import {translate} from "./translations";
import {css, styled} from '@mui/system';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useWebApp from "./twa/useWebApp";
import {useNavigate} from "react-router-dom";
import StarIcon from "@mui/icons-material/Verified";

const Section = styled(Box)(({ themeParams }) => ({
    padding: '16px',
    backgroundColor: themeParams.section_bg_color,
    borderRadius: '10px',
    marginTop: '16px'
}));

const SectionCard = styled(Card)(({ themeParams }) => ({
    padding: '16px',
    backgroundColor: themeParams.section_bg_color,
    borderRadius: '10px',
    backgroundImage: 'none',
    marginTop: '16px'
}));


const CList = styled(List)(({ themeParams }) => ({
    padding: 0,
}));

const Label = styled(Typography)(({ themeParams }) => ({
    color: themeParams.section_header_text_color,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    marginBottom: '0px',
    marginTop: '16px'
}));

const StyledCard = styled(Card)(({ themeParams }) => ({
    backgroundColor: themeParams.section_bg_color,
    borderRadius: '20px',
    padding: '20px',
    marginBottom: '20px',
}));

const StyledTypography = styled(Typography)(({ themeParams }) => ({
    color: themeParams.text_color,
}));

const Profile = ({ user, language, themeParams }) => {
    const navigate = useNavigate();
    let WebApp = useWebApp();

    const CustomStarIcon = styled(StarIcon)(
        css({
            color: `#33ff00 !important`,
            marginBottom: '5px',
        })
    );

    // Function to convert hex to rgb
    const hexToRgb = (hex) => {
        let bigint = parseInt(hex.substring(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
    };

    const rgbToCss = (rgb) => `rgb(${rgb.join(', ')})`;

    const lightenColor = (rgb, amount) => {
        return rgb.map(value => Math.min(255, value + amount));
    };

    const primaryColorRgbArray = hexToRgb(themeParams.secondary_bg_color); // --tg-theme-bg-color
    const primaryColorRgb = rgbToCss(primaryColorRgbArray);

    // Apply transformation to get the secondary color
    const secondaryColorRgbArray = lightenColor(primaryColorRgbArray, 50); // Increase brightness
    const secondaryColorRgb = rgbToCss(secondaryColorRgbArray);

    useEffect(() => {
        if (WebApp) {
            WebApp.BackButton.show();
            WebApp.BackButton.onClick(function () {
                navigate(`/`);
            });
            return () => {
                WebApp.BackButton.hide();
            };
        }
    }, [WebApp]);

    const getUserPlan = (user) => {
        if (!user || !user.subscriptions) {
            return 'Unsubscribed';
        }

        const role = user.subscriptions;
        if (role.includes('unlimited')) {
            return 'Unlimited';
        } else if (role.includes('premium')) {
            return 'Premium';
        } else if (role.includes('standard')) {
            return 'Standard';
        } else if (role.includes('free')) {
            return 'Free';
        }

        return 'Unsubscribed';
    };

    // get roles
    const getUserRoles = (user) => {
        if (!user || !user.roles) {
            return translate(language, 'no_roles');
        }

        let roleResult = '';
        for (let role of user.roles) {
            roleResult += translate(language, role)
            if (role !== user.roles[user.roles.length - 1]) {
                roleResult += ', ';
            }
        }


        return roleResult;
    }

    const currentSubscriptionInfo = user?.subscription_info;
    const currentUserPlan = getUserPlan(user);

    const languages = [
        { code: 'en', label: 'English', localized: 'English' },
        { code: 'ru', label: 'Russian', localized: 'Русский' },
        { code: 'es', label: 'Spanish', localized: 'Español' },
        { code: 'fr', label: 'French', localized: 'Français' },
        { code: 'de', label: 'German', localized: 'Deutsch' },
        { code: 'zh', label: 'Chinese', localized: '中文' }
    ];

    const findLanguage = (code) => {
        return languages.find(lang => lang.code === code);
    }

    let subscriptionDescription = (
        <>
            {user?.subscriptions && user.subscriptions.length > 0 ? (
                <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign:'center', paddingBottom: '15px', paddingTop: '5px' }}><CustomStarIcon sx={{ marginRight: '8px' }} /> {translate(language, 'subscription_is_active')}</Typography>
            ) : (
                <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign:'center', paddingBottom: '15px', paddingTop: '5px' }}>🙅‍♂️ {translate(language, 'subscription_is_inactive')}</Typography>
            )}
            {currentSubscriptionInfo && (
                <span>
                    <Typography variant="body2">🎟️ {translate(language, currentUserPlan.toLowerCase())}</Typography>
                    <Typography variant="body2">📅 {translate(language, 'subscription_end_date')}: {
                        new Date(currentSubscriptionInfo.end_date).toLocaleDateString(
                            language === 'en' ? 'en-US' : 'ru-RU',
                            { day: '2-digit', month: '2-digit', year: 'numeric' }
                        )
                    }</Typography>
                </span>
            )}
        </>
    );

    return (
        <Box sx={{ padding: 2, minHeight: '100vh' }}>
            <StyledCard
                themeParams={themeParams}
                sx={{
                    textAlign: 'center',
                    padding: 4,
                    borderRadius: '20px',
                    background: `linear-gradient(${primaryColorRgb} 10.06%, ${secondaryColorRgb} 128.06%)`,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <Avatar
                        src={user?.avatar}
                        alt={user?.full_name || 'Noname'}
                        sx={{
                            width: 120,
                            height: 120,
                            border: `4px solid ${themeParams.accent_color}`,
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        }}
                    />
                    <StyledTypography
                        themeParams={themeParams}
                        variant="h5"
                        sx={{ fontWeight: 600, marginTop: 2 }}
                    >
                        {`${user?.full_name || ''}`.trim()}
                    </StyledTypography>
                    <Typography variant="body2" sx={{ fontSize: '14px', color: themeParams.section_header_text_color }}>
                        {getUserRoles(user)}
                    </Typography>
                </Box>
            </StyledCard>

            {/* Subscription Info */}
            <Label themeParams={themeParams}>{translate(language, 'subscription_block_title')}</Label>
            <Section themeParams={themeParams}>
                <Typography variant="body2" color="textSecondary">{subscriptionDescription}</Typography>
            </Section>

            {/* Language Selection Section */}
            <Label themeParams={themeParams}>{translate(language, 'language')}</Label>
            <Section themeParams={themeParams}>
                <CList>
                    <ListItem button onClick={() => navigate('/profile/language')}>
                        <ListItemText primary={findLanguage(language).label + ' ('+findLanguage(language).localized+')'} />
                        <ListItemSecondaryAction>
                            <ChevronRightIcon />
                        </ListItemSecondaryAction>
                    </ListItem>
                </CList>
            </Section>

            {/* Additional Info (Styled as on Screenshot) */}
            <Label themeParams={themeParams}>{translate(language, 'other_block_title')}</Label>
            <Section themeParams={themeParams}>
                <CList>
                    <ListItem button component="a" onClick={() => {
                        if (WebApp) {
                            WebApp.openTelegramLink('https://t.me/coobapp');
                        }
                    }} rel="noopener noreferrer">
                        <ListItemIcon><LightbulbIcon sx={{ color: '#E53935' }} /></ListItemIcon>
                        <ListItemText primary={translate(language, 'news')} />
                        <ListItemSecondaryAction>
                            <ChevronRightIcon />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button component="a" href="https://docs.coob.app/getting-started/examples-of-courses" target="_blank" rel="noopener noreferrer">
                        <ListItemIcon>📱</ListItemIcon>
                        <ListItemText primary={translate(language, 'examples')} />
                        <ListItemSecondaryAction>
                            <ChevronRightIcon />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button component="a" onClick={() => {
                        if (WebApp) {
                            WebApp.openTelegramLink('https://t.me/coob_app_support_bot');
                        }
                    }} rel="noopener noreferrer">
                        <ListItemIcon><ChatBubbleIcon sx={{ color: '#1E88E5' }} /></ListItemIcon>
                        <ListItemText primary={translate(language, 'support')} />
                        <ListItemSecondaryAction>
                            <ChevronRightIcon />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button component="a" href="https://docs.coob.app" target="_blank" rel="noopener noreferrer">
                        <ListItemIcon><HelpOutlineIcon sx={{ color: '#757575' }} /></ListItemIcon>
                        <ListItemText primary={translate(language, 'faq')} />
                        <ListItemSecondaryAction>
                            <ChevronRightIcon />
                        </ListItemSecondaryAction>
                    </ListItem>
                </CList>
            </Section>
        </Box>
    );
};

export default Profile;