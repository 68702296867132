import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Container,
    Slide,
    Typography,
    Box,
    CircularProgress,
    Avatar,
    IconButton,
    Backdrop,
    Table,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { translate } from './translations';
import useWebApp from "./twa/useWebApp";

const StyledCard = styled(Box)(({ themeParams }) => ({
    color: themeParams.text_color,
    textAlign: 'center',
}));

const StyledTypography = styled(Typography)(({ themeParams }) => ({
    color: themeParams.text_color,
    marginBottom: '8px',
}));

const SpinnerContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
}));

const HeaderContainer = styled(Box)(({ themeParams }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${themeParams.section_separator_color}`,
    paddingBottom: '8px',
    marginBottom: '16px',
}));

const StyledTableContainer = styled(Box)(({ themeParams }) => ({
    maxHeight: '200px',
    overflowY: 'auto',
    marginTop: '16px',
    border: `1px solid ${themeParams.section_separator_color}`,
    borderRadius: '8px',
}));

const StyledTable = styled(Table)(({ themeParams }) => ({
    width: '100%',
    borderCollapse: 'collapse',
    '& td, & th': {
        padding: '8px 12px',
        border: `1px solid ${themeParams.section_separator_color}`,
    },
    '& td': {
        fontSize: '14px',
    },
}));

const SubscriptionDialog = ({ themeParams, open, onClose, language }) => {
    const [loading, setLoading] = useState(true);
    const [plan, setPlan] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState('standard');
    const token = useSelector(state => state.accessToken);
    const isHandlerSet = useRef(false);
    let WebApp = useWebApp();

    useEffect(() => {
        const handleClick = async () => {
            WebApp.MainButton.showProgress(false);
            try {
                if (plan && plan.code === "free" && !plan.IsDisabled) {
                    const response = await axios.post('https://api.coob.app/v1/plans/free', {
                        plan_id: selectedPlan
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });

                    if (response.status === 200) {
                        window.location.reload();
                    }
                } else {
                    const response = await axios.post(
                        'https://api.coob.app/v1/plans/payment-link',
                        { planId: selectedPlan },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    if (response.data && response.data.data.link) {
                        let invoiceLink = response.data.data.link;
                        WebApp.openInvoice(invoiceLink, (status) => {
                            if (status === "paid") {
                                window.location.reload();
                            }
                        });

                        console.log('Payment link:', response.data.data.link);
                    }
                }

                WebApp.MainButton.hideProgress();
            } catch (error) {
                WebApp.MainButton.hideProgress();
                console.error('Failed to fetch payment link or activate free plan:', error);
                WebApp.showAlert("error: " + (error.response?.data?.data?.message || error.message));
            }
        };

        let buttonName = translate(language, 'subscription_now');
        if (plan && plan.code === "free" && !plan.IsDisabled) {
            buttonName = translate(language, 'activate_free_plan');
        }

        if (open && WebApp) {
            WebApp.MainButton.show();
            WebApp.MainButton.setParams({
                text: buttonName,
            });

            WebApp.MainButton.onClick(handleClick);
        }

        return () => {
            if (WebApp) {
                WebApp.MainButton.offClick(handleClick);
                WebApp.MainButton.hideProgress();
                WebApp.MainButton.hide();
            }
        };
    }, [open, WebApp, selectedPlan]);

    useEffect(() => {
        const fetchPlan = async () => {
            try {
                const response = await axios.get('https://api.coob.app/v1/plans', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });

                if (response.data && response.data.code === 'success') {
                    const EnablePlan = response.data.data.find(p => p.IsDisabled === false);
                    if (EnablePlan) {
                        setPlan({
                            name: translate(language, EnablePlan.Conditions.name.toLowerCase()),
                            code: EnablePlan.Conditions.name.toLowerCase(),
                            cover: EnablePlan.Cover,
                            description: EnablePlan.Conditions.name.toLowerCase() === 'free' ? `${translate(language, "price")} : ${parseFloat(EnablePlan.Conditions.price).toFixed(2)} ⭐` :  `${translate(language, "price")} : ${parseFloat(EnablePlan.Conditions.price).toFixed(2)} ⭐ / ${translate(language, 'in_month')}`,
                            month: EnablePlan.SubscriptionMonths,
                            features: [
                                {
                                    label: translate(language, 'creating_courses'),
                                    available: true,
                                },
                                {
                                    label: translate(language, 'create_bot'),
                                    available: true,
                                },
                                {
                                    label: translate(language, 'invoice_step'),
                                    available: EnablePlan.Conditions.invoice_step_available,
                                },
                                {
                                    label: translate(language, 'assignment_plugin'),
                                    available: EnablePlan.Conditions.assignment_plugin_available,
                                },
                                {
                                    label: translate(language, 'export_course'),
                                    available: EnablePlan.Conditions.export_course_available,
                                },
                                {
                                    label: translate(language, 'student_statistics'),
                                    available: EnablePlan.Conditions.student_statistics_available,
                                },
                                {
                                    label: translate(language, 'course_statistics'),
                                    available: EnablePlan.Conditions.course_statistics_available,
                                },
                                {
                                    label: translate(language, 'custom_plugin'),
                                    available: EnablePlan.Conditions.custom_plugin_available,
                                },
                                {
                                    label: translate(language, 'ai_generator'),
                                    available: EnablePlan.Conditions.ai_generator_available,
                                },
                            ],
                        });
                        setSelectedPlan(EnablePlan.Id);
                    }
                }
            } catch (error) {
                console.error('Failed to fetch plan:', error);
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchPlan();
        }
    }, [open, token]);

    return (
        <>
            <Backdrop open={open} onClick={onClose} sx={{ zIndex: 1200, backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
            <Slide direction="up" in={open} mountOnEnter unmountOnExit>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1300,
                        backgroundColor: themeParams.section_bg_color,
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                        padding: '16px',
                        color: themeParams.text_color,
                    }}
                    onClick={onClose}
                >
                    <Container onClick={(e) => e.stopPropagation()}>
                        <HeaderContainer themeParams={themeParams}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {translate(language, 'subscription_now')}
                            </Typography>
                            <IconButton
                                sx={{ color: themeParams.text_color }}
                                onClick={onClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </HeaderContainer>
                        {loading ? (
                            <SpinnerContainer>
                                <CircularProgress
                                    sx={{
                                        color: themeParams.button_color,
                                    }}
                                />
                            </SpinnerContainer>
                        ) : plan ? (
                            <StyledCard themeParams={themeParams}>
                                <Avatar
                                    alt={plan.name}
                                    src={plan.cover}
                                    variant="square"
                                    sx={{ width: 100, height: 100, margin: '0 auto 10px' }}
                                />
                                <StyledTypography variant="h5" themeParams={themeParams}>
                                    {plan.name}
                                </StyledTypography>
                                {plan.code !== "free" && (
                                <StyledTypography variant="body1" themeParams={themeParams}>
                                    {plan.description}
                                </StyledTypography>
                                )}
                                {plan.code === "free" && (
                                <StyledTypography variant="body1" themeParams={themeParams}>
                                    {plan.month} {translate(language, 'month')}
                                </StyledTypography>
                                    )}
                                <StyledTableContainer themeParams={themeParams}>
                                    <StyledTable themeParams={themeParams}>
                                        <TableBody>
                                            {plan.features.map((feature, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{feature.label}</TableCell>
                                                    <TableCell>
                                                        <span style={{ color: feature.available ? '#32CD32' : 'gray' }}>
                                                            {feature.available ? translate(language, 'yes') : translate(language, 'no')}
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </StyledTable>
                                </StyledTableContainer>
                            </StyledCard>
                        ) : (
                            <Typography>{translate(language, 'no_plan_found')}</Typography>
                        )}
                    </Container>
                </Box>
            </Slide>
        </>
    );
};

export default SubscriptionDialog;